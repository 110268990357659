
export const filtrarCiudades = (array) => {
  
    let cities = array.map(element => element.ciudad).sort()
    const citiesJson = [];
    cities.forEach((item)=>{
    	//pushes only unique element
        if(!citiesJson.includes(item)){
    		citiesJson.push(item);
    	}
    })

    return citiesJson;
}


export const filtrarTiendasPorCiudad = (array, ciudad) => {
  let tiendas = array.filter(elemento  => {
     return elemento.ciudad === ciudad;
  }).map(elemento => elemento.nombre_cliente);

  return tiendas;
}

export const obtenerZona = (ciudad, tienda, array) => {
  let zonaObtenida = array.filter(elemento => {
    return elemento.nombre_cliente === tienda && elemento.ciudad === ciudad
  })

  return zonaObtenida[0].zona;
}

export const obtenerDatosAdicionales = (ciudad, tienda, array) => {
  let datosAdicionales = array.filter(elemento => {
    return elemento.nombre_cliente === tienda && elemento.ciudad === ciudad
  })

  return datosAdicionales[0];
}