<template>
  <div class="home">
    <div class="img-descuentos"></div>
    <div class="container-fluid text-container d-flex">
      <div class="row container mx-auto">
        <div class="col-12 mt-3">
          <button type="button" class="btn btn-register fw-bold d-flex btn-lg mt-lg-0 ms-auto"><router-link class="text-decoration-none" to="/registro">Registrarse</router-link></button>
        </div>
        <div class="col-md-6 col-12">
          <img  class="img-fluid mx-auto d-block imgAniversario" src="img/Logo.svg" alt="sale">
          <img class="img-fluid mx-auto d-block imgDescuento mt-2" src="img/Descuento.svg">
          <h6 class="text-center w-semibold text-white text-uppercase text-dates my-2">14 y 15 de septiembre de 2024</h6>
          <img class="img-fluid mx-auto d-block imgInvited" src="img/Invited.svg">
          <p class="text-center w-semibold text-white text-uppercase text-dates mt-3">Aplica en distribuidores autorizados</p>

        </div>
      </div>
    </div>
    <div class="distribuidores py-5" id="distribuidores">
      <div class="container">
        <div class="row mx-0">
          <div class="col-12">
            <h3 class="text-center text-uppercase text-acmno mb-5">Distribuidores autorizados</h3>
            <div class="table-responsive">
              <table id="dataTable" class="table table-striped" style="width:100%">
                <thead>
                    <tr>
                        <th>Ciudad</th>
                        <th>Zona</th>
                        <th>Tienda</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(store, index) in stores" :key="index">
                        <td>{{store.ciudad}}</td>
                        <td>{{store.zona}}</td>
                        <td>{{store.tienda}}</td>
                    </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>

import Stores from '../stores.json'
import Footer from '@/components/Footer.vue'

export default {
  name: 'HomeView',
  data() {
    return {
      stores: []
    }
  },
  created(){
    Stores.map(elemento => {
      this.stores.push({
        ciudad: elemento.ciudad,
        zona: elemento.zona,
        tienda: elemento.nombre_cliente,
      })
    });
    this.stores.sort((a, b) => a.ciudad.localeCompare(b.ciudad));
  },
  components: {
    Footer
  }

}
</script>
