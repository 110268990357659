<template>
  <div>
    <div class="img-descuentosForm p-4 d-flex justify-content-between align-items-center">
      <div>
        <img  class="img-fluid mx-auto d-block logoForm" src="img/Logo.svg" alt="sale">

        <!-- <img class="img-fluid d-block logoPequeno" src="img/Descuento.svg" alt="30%descuento"> -->
      </div>
      <div>
        <img class="img-fluid d-block logoFormulario" src="img/marca.svg">
      </div>
    </div>
    <div class="container">
      <div class="row mt-md-4 mt-3">
        <div class="col-12 mb-3">
          <h3 class="fw-bold text-uppercase text-blue">Formulario de registro</h3>
        </div>
        <div class="col-md-6 col-12 mb-3">
          <p class="text-center">¿En que <b>ciudad</b> se realiza la compra? <b class="text-danger">*</b></p>
          <div class="d-grid gap-2 col-10 col-md-5 mx-auto">
            <Select2 class=" select-premios" id="selectCiudades" v-model.trim="ciudadSeleccionada" :options="ciudades" placeholder="Seleccione una ciudad" />
          </div>
        </div>
        <div class="col-md-6 col-12 mb-3">
          <p class="text-center">¿Desde que <b>tienda</b> participa? <b class="text-danger">*</b></p>
          <div class="d-grid gap-2 col-10 col-md-5 mx-auto">
            <Select2 v-model.trim="tiendaSeleccionada" :options="tiendas" :settings="{ settingOption: value, settingOption: value }"  @select="selectStore()" placeholder="Seleccione una tienda" />
          </div>
        </div>
        <hr class="m-3 d-none d-md-block">
        <div class="col-md-6 col-12 mb-3">
          <p class="text-center">Ingresa el número de <b>la factura</b> <b class="text-danger">*</b></p>
          <div class="d-grid gap-2 col-10 col-md-5 mx-auto">
            <input v-model.trim="numFactura" class="form-control" type="text" id="numFactura" placeholder="Número de factura">
          </div>
        </div>
        <div class="col-md-6 col-12 mb-3">
          <p class="text-center">Adjuntar o tomar fotografía de <b>la factura</b> <b class="text-danger">*</b></p>
          <div class="d-flex gap-2 col-10 col-md-5 mx-auto justify-content-center">
            <div class="input-file d-flex">
              <input accept="image/*" ref="imgFacturaRef" @change="imgOnChange($event)" class="form-control" type="file" id="imgFactura">
            </div>
          </div>
        </div>
        <hr class="m-3 d-none d-md-block">
        <div class="col-12 mb-3">
          <h5 class="fw-bold text-blue text-uppercase">Referencias seleccionadas</h5>
        </div>
        <div class="col-12">
          <div class="d-md-flex d-block align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <button class="btn btn-link" @click="agregarFila(referencias)"><img src="img/add-button.svg"></button><p class="mb-0">Agregar más referencias <b class="text-danger">*</b></p>
            </div>
          </div>
        </div>
        <div class="col-12" v-if="refenciasSinRegistro">
            <div class="alert alert-danger" role="alert">
                <b>¡Debe agregar almenos un registro de referencia por medio del botón "+"!</b>
            </div>
        </div>

        <div class="container">
          <div class="row" v-for="(input, index) in referencias" v-bind:key="`inputsRef-${index}`">
            <div class="col-lg-10 col-12 offset-lg-1">
              <p class="mt-3">Ingresa la <b>referencia</b> de la prenda</p>
              <div class="d-flex justify-content-between">
                <input maxlength="7" class="form-control input-premios mb-lg-0 mb-2 inputRef" type="text" v-model="input.ref" placeholder="Ingresar referencia" v-bind:id="`inputReferencia${index + 1}`">
                <button class="btn btn-link" @click.prevent="eliminarFila(index, referencias)"><img src="img/delete-icon.svg"></button>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-3" v-if="refInvalida">
          <div class="col-12">
            <div class="alert alert-danger text-cenetr" role="alert">
              <p><b>¡Todas referencias diligenciadas deben tener un formato válido!</b></p>
              <p class="m-0">Para más información diríjase a la sección <em><b>¿CÓMO ENCONTRAR LAS REFERENCIAS EN LAS ETIQUETAS DE CADA PRENDA?</b></em></p>
            </div>
          </div>
        </div>
        <div class="mt-3" v-if="camposFaltantesRef">
          <div class="col-12">
            <div class="alert alert-danger" role="alert">
                <b>¡Para agregar otra referencia debe diligenciar los dos campos respectivos!</b>
            </div>
          </div>
        </div>
        <div class="mt-3" v-if="camposFaltantes">
          <div class="col-12">
            <div class="alert alert-danger" role="alert">
                <b>¡Antes de realizar el registro debe completar todos los campos marcados con <b class="text-danger">*</b> !</b>
            </div>
          </div>
        </div>
        <div class="mt-3" v-if="extensionArchivoIncorrecta">
          <div class="col-12">
            <div class="alert alert-danger" role="alert">
                <b>¡El archivo adjunto debe ser una imagen <b>(Extensiones admitidas: png, jpg, jpeg, bmp o pdf")</b>!</b>
            </div>
          </div>
        </div>
        <div class="row mt-3" v-if="serverResponse">
          <div class="col-12">
            <div v-bind:class="!postResponse ? 'alert alert-danger' : 'alert alert-success'"  role="alert">
                <b>{{msgResponse}}</b>
            </div>
          </div>
        </div>
        <p class="text-center mt-5" v-if="loading"><b>Enviando información, por favor espere...</b></p> 
        <div class="d-flex justify-content-center" v-if="loading">
          <pulse-loader :loading="loading" :color="color" :size="size"></pulse-loader>
        </div>
        <div class="d-flex justify-content-end mt-5 mb-5">
          <button type="button" class="btn btnEnviar fw-bold" @click="crearRegistro">Enviar</button>
        </div>
      
        <!-- Modales -->
        <div class="modal fade" id="modalRegistroExitoso" ref="modalRegistroExitoso" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalRegistroExitosoLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content img-salesForm">
                <div class="modal-body text-center p-5">
                  <img  class="img-fluid mx-auto d-block logoPequeno" src="img/Descuento.svg" alt="30%descuento">
                  <h6 class="mt-3 text-white">Registro finalizado con éxito</h6>
                  <p class="mt-3 text-white">Gracias por participar</p>
                  <img class="img-fluid mx-auto d-block" src="img/marca.svg" width="260px" alt="AMERICANINO|CHEVIGNON|ESPRIT">
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btnEnviar fw-bold mx-auto" @click="limpiaCampos()">Ingresar un nuevo registro</button>
                  <a href="/" type="button" class="btn btnEnviar fw-bold mx-auto">Finalizar</a>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid bg-denim">
          <div class="row p-5 d-flex align-items-center">
            <div class="col-md-6 col-12 mb-3">
              <h3 class="fw-semibold text-uppercase text-white">¿Cómo encontrar las referencias en las etiquetas de cada prenda?</h3>
            </div>
            <div class="col-md-6 col-12">
              <p class="text-center fw-semibold text-uppercase text-white">Así puedes encontrar la referencia de cada prenda</p>
              <img class="img-fluid mx-auto d-block" src="img/referencias.png">
            </div>
          </div>
        </div>
    <Footer/>
  </div>
</template>

  
<script>
  import API from '../api'
  import stores from '../stores.json'
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
  import { filtrarCiudades, filtrarTiendasPorCiudad, obtenerDatosAdicionales } from '../helpers/filtros'
  import { validaReferecias, validaNumerico, validaString, validaExtensionArchivo } from '../helpers/validaciones'
  import Footer from '@/components/Footer.vue'

  import CurrencyInput from '@/components/CurrencyInput.vue'
  import Select2 from 'vue3-select2-component'
  import Compressor from 'compressorjs';

  export default {
    name: 'SalesForm',
    data() {
      return{
        ciudadSeleccionada: '',
        tiendaSeleccionada: '',
        cliente: '',
        codigoCliente: '',
        zona: '',
        numFactura: '',
        ciudades: [],
        tiendas: [],
        camposFaltantesRef: false,
        camposFaltantes: false,
        refenciasSinRegistro: false,
        extensionArchivoIncorrecta: false,

        referencias: [{
          marca: 'Americanino',
          ref: ''
        }],
        imgFactura: "",
        imgProps: {
          imgName: "",
          imgType: "",
        },

        msgResponse: "",
        serverResponse: false,
        postResponse: false,
        refInvalida: false,
        loading: false,
      }
    },
    components: {
      CurrencyInput,
      PulseLoader,
      Select2,
      Footer,
    },
    methods: {

      selectStore(){
        const { zona, cliente, codigo } = obtenerDatosAdicionales(this.ciudadSeleccionada, this.tiendaSeleccionada, stores);
        this.zona = zona;
        this.cliente = cliente;
        this.codigoCliente = codigo;
      },
  
      agregarFila: function(referencias) {
        console.log(referencias)

        this.ocultaAlertas();

        referencias.forEach(referencia => {
          if(referencia.marca.trim() === "" || referencia.ref.trim() === "") {
            this.camposFaltantesRef = true;
          }
        });

        if(this.camposFaltantesRef) return;

        const refValidada = validaReferecias(this.referencias);

        if(!refValidada) {
          return this.refInvalida = true;
        }

        
        referencias.push({
          marca: 'Americanino',
          ref: '',
        });
      },

      eliminarFila: function(index, referencias) {
        referencias.splice(index, 1);
        this.sumaTotal();
      },

      validaCampos: function() {

        this.ocultaAlertas();

        if(this.referencias.length === 0) {
          this.refenciasSinRegistro = true;
          return true;
        }

        // Validando campos vacíos
        if(this.ciudadSeleccionada === "" || this.tiendaSeleccionada === "" 
            || this.zona === "" || this.numFactura === "" || this.imgFactura === "") {
          this.camposFaltantes = true;
          return true;
        }

        //Valida extension de archivo
        if(!validaExtensionArchivo(this.imgFactura)) {
          this.extensionArchivoIncorrecta = true;
          return true;
        }
        
        let aux = false;
        this.referencias.forEach(referencia => {
          if(referencia.marca.trim() === "" || referencia.ref.trim() === "" || referencia.valorUnitario === 0) {
            this.camposFaltantes = true;
            aux = true;
          }
        });
        if(aux) return true;
        
      },

      sumaTotal: function() {
        this.total = this.referencias.map(referencia => referencia.valorUnitario).reduce((accumulated, currentValue) => {
            return accumulated + currentValue
        }, 0);
      },

      crearRegistro: async function() {

        this.ocultaAlertas();

        let camposFaltantes = this.validaCampos();

        if(camposFaltantes) return;

        const { imgName, imgType } = this.imgProps;
        this.imgFactura = new File([this.imgFactura], imgName, {
          type: imgType 
        });

        const refValidada = validaReferecias(this.referencias);

        if(!refValidada) {
          return this.refInvalida = true;
        }

        const referenciasUppercase = this.referencias.map(referencia => {
          return {
            marca: referencia.marca,
            ref: referencia.ref.toUpperCase()
          }
        });

        const data = new FormData();
        data.append('ciudad', this.ciudadSeleccionada);
        data.append('tienda', this.tiendaSeleccionada);
        data.append('cliente', this.cliente.trim());
        data.append('codigoCliente', this.codigoCliente.trim());
        data.append('zona', this.zona.trim());
        data.append('numFactura', this.numFactura.toUpperCase());
        data.append('imgFactura', this.imgFactura == "" ? "N/A" : this.imgFactura);
        data.append('referencias', JSON.stringify(referenciasUppercase));

        this.loading = true;
        const addInfo =  await API.crearRegistroDeUsuario(data);

        this.msgResponse = addInfo.msg;

        if(!addInfo.ok){
          this.loading = false;
          this.serverResponse = true;
          return;
        }
        this.loading = false;
        $('#modalRegistroExitoso').modal('show');
      },

      limpiaCampos: function() {
        this.ciudadSeleccionada = "";
        this.tiendaSeleccionada = "";
        this.nombreAsesor = "";
        this.identificacionAsesor = "";
        this.cliente = '',
        this.codigoCliente = '',
        this.zona = '',
        this.numFactura = "";
        this.referencias = new Array({
          marca: '',
          ref: '',
        });
        this.$refs.imgFacturaRef.value=null;
        this.imgFactura = ''
        let botones = document.querySelectorAll('.btnMarca');
        botones.forEach(boton => boton.classList.remove('btnMarcaActive'));

        $('#modalRegistroExitoso').modal('hide');
        this.ocultaAlertas();
      },

      ocultaAlertas: function() {
        this.refenciasSinRegistro = false;
        this.camposFaltantesRef = false;
        this.camposFaltantes = false;
        this.serverResponse = false;
        this.postResponse = false;
        this.msgResponse = "";
        this.refInvalida = false;
        this.extensionArchivoIncorrecta = false;
      },

      imgOnChange: function(event){
        this.imgFactura = this.$refs.imgFacturaRef.files[0];
        this.imgProps.imgName = this.imgFactura.name;
        this.imgProps.imgType = this.imgFactura.type;

        if(!this.isLt2MB(this.imgFactura)) {
          new Compressor(this.imgFactura, {
            quality: 0.7, // Adjust the desired compression quality
            maxWidth: 800, // Adjust the desired maximum width
            maxHeight: 800, // Adjust the desired maximum height
            success: (compressedFile) => {
              this.imgFactura = compressedFile;
            },
            error: (error) => {
              console.log('Error al comprimir la imagen:', error.message);
            },
          });
        }
      },
      isLt2MB(file) {
        return file.size / 1024 / 1024 < 2;
      }
    },

    created(){
        let ciudades = filtrarCiudades(stores);
        this.ciudades = ciudades;
    },

    watch: {
      ciudadSeleccionada: function(value) {
        this.tiendaSeleccionada = '';
        this.tiendas = filtrarTiendasPorCiudad(stores, value);
      },
    }
  };
</script>
  
